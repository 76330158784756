import React, {useContext, useEffect, useState} from 'react';



let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

function App() {
    const CTA = [
        {key: "Make it Better 🔥", nextScore: 1},
        {key: "Innovate!", nextScore: 1},
        {key: "Raise More Money!", nextScore: 1},
        // {key: "Promise To Stop Astro-turfing!", nextScore: -1},
        // {key: "Fire The Founder, Hire a Serial CEO!", nextScore: -1},
        {key: "Synergize!", nextScore: 1},
        {key: "Free Egress", nextScore: 1},
        {key: "Free Storage!!!! 🔥🔥🔥", nextScore: 10},
        // {key: "Realize You Gave Away Too Much Free Egress", nextScore: -5},
        {key: "Talk About Brand Value!", nextScore: 1},
        {key: "Strategize!", nextScore: 1},
        {key: "Integrate!", nextScore: 1},
        {key: "Content Marketing!", nextScore: 1},
        {key: "Think Outside The Box!", nextScore: 1},
    ]
    const [word, setWord] = useState({key: "Make it Better 🔥", nextScore: 1})
    const [letter, setLetter] = useState(18)
    const [number,setNumber] = useState(3)
    const [index, setIndex] = useState(1)
    const [score, setScore] = useState(0)
    const [wiggle, setWiggle] = useState(100000)
    const [totalCount, setTotalCount] = useState(0)
    function getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    useEffect(() => {
        console.log("Fixed the bug, Thank you tdeck")
    }, [])

    const handleButtonPress = () => {
        let newNumber = number - word.nextScore
        setNumber(() => newNumber)
        let newLetter = letter - word.nextScore
        if(newLetter > 25){
            newLetter = newLetter - 26
        }
        if(newLetter < 0){
            newLetter = newLetter + 26
        }
        setLetter(newLetter)
        let newScore = score + word.nextScore
        setScore(() => newScore)
        let randNumber = getRandomInt(CTA.length)
        while (randNumber === index){
            randNumber = getRandomInt(CTA.length)
        }
        setIndex(randNumber)
        setWord(() => CTA[index])
        setTotalCount(() => totalCount + 1)
    }

    useEffect(() =>{
        if(totalCount === 69){
            setNumber(-420)
        }
    }, [totalCount])

    useEffect(() => {
        if(score < 5){
            setWiggle(10000)
        }
        if (score > 5) {
            setWiggle(.5)
        }
        if (score > 10) {
            setWiggle(.3)
        }
        if (score > 15) {
            setWiggle(.1)
        }
        if (score > 25) {
            setWiggle(.03)
        }
    }, [score])



    return (
        <div
            className="flex flex-col h-full items-center justify-center p-4 text-white bg-gradient-to-br from-gray-600 via-teal-700 to-gray-800 overflow-x-hidden">
            <h1 className={"text-4xl font-bold"}>Object Storage Name Generator</h1>
            <p className={"mt-4 text-xl"}>Level of Goodness</p>
            <div style={{    animation: `wiggle ${wiggle}s infinite`}} className={"transition-all relative bg-gray-200 h-[12px] w-[300px] mt-6 wiggle"}>
                <div data-score={score} style={{width: score * 15 +"px"}} className={"absolute bg-green-500 top-0 bottom-0 left-0 "}></div>
            </div>
            {/*<p className={"text-center mt-4"}>That's like... Pretty Good</p>*/}
            <p className={"text-4xl block font-black mt-16 space-x-4"}><span
                className={"p-6 bg-blueGray-800 rounded-xl"}>{letters[letter]}</span><span
                className={"p-6 bg-blueGray-800 rounded-xl"}>{number}</span></p>
            {}
            <button onClick={() => handleButtonPress()}
                    className={"bg-violet-900 text-white px-8 py-3 rounded-xl mt-20 text-2xl sm:text-4xl font-bold"}>{word.key}</button>
            <div className={"mt-20"}>
                <p>Made with love by the team at <a className={"text-blue-200"} href={"https://www.taloflow.ai"}>Taloflow</a>. Inspired by the not-so-subtle move <a className={"text-blue-200"} href={"https://www.taloflow.ai/blog/will-cloudflare-r2-win-customers-from-amazon-s3"}>taken by Cloudflare</a></p>
            </div>
        </div>
    );
}

export default App;
